import {useQuery} from "@tanstack/vue-query";
import {PublicApi} from "~/gen/openapi/portalService";
import {useLocaleStore} from "~/stores/locale";

export const useHelpTextsService = () => {
    const { setLocaleMessage, messages } = useI18n()

    const { data, isFetching, isError, error } = useQuery({
        queryKey: ['publicGetHelpTexts'],
        queryFn: async () => {
            const { $axios, $config } = useNuxtApp()
            const publicApi = new PublicApi(undefined, $config.public.apiBaseHost, $axios)

            return (await publicApi.publicGetHelpTexts()).data
        },
        staleTime: 10 * 60 * 1000,
        refetchOnWindowFocus: false,
    })

    watch(() => data.value, () => {
        const response: any = data.value
        Object.keys(response).forEach((key) => {
            if (response[key] != null) {
                const languageResponse = response[key]
                const filteredTranslations = {}
                const responseKeys = Object.keys(languageResponse)

                // Loop through response keys
                for (let i = 0; i < responseKeys.length; i++) {
                    const translationKey = responseKeys[i];
                    const existingTranslation = messages.value[key] && messages.value[key][translationKey];

                    // Add translation only if it doesn't already exist
                    if (languageResponse[translationKey] && !existingTranslation) {
                        // @ts-ignore
                        filteredTranslations[translationKey] = languageResponse[translationKey];
                    }
                }

                // Merge filtered translations with existing messages
                setLocaleMessage(key, Object.assign({}, messages.value[key], filteredTranslations));
            }
        });
    })

    const helpTexts = computed(() => {
        const localeStore = useLocaleStore()
        const languageCode = (localeStore.locale) || 'no'

        // @ts-ignore
        return data.value && languageCode in data.value ? data.value[languageCode] : null
    })

    return {
        helpTexts,
        isFetching,
        error
    }
}